export default {
  follow_me: 'Follow me',
  menu: {
    home: 'Home',
    all: 'All',
    contact: 'About me',
    about: 'About',
    patterns: 'Patterns',
    graphic: 'Graphics Design',
    uxui: 'UX/UI',
    posters: 'Posters',
    illustrations: 'Illustrations',
    water_colours: 'Water Colours',
    halloween: 'Halloween',
    contest: `Contest`,
    polish_venus: 'Polish Venus',
    pastel_syrup: 'Pastel Syrup',
    japanese_garden: 'Japanese Garden',
    colour_block_abstract: 'Colour Block bstract '
  }
}
