<template>
  <v-footer
    dense
    inset
    app
    class="font-weight-this; overline"
  >
    <v-col
      class="text-right"
      cols="12"
    >
      <v-row>
        <span class="overline">
        {{ $vuetify.lang.t('$vuetify.follow_me') }}
        </span>
        <a href="https://www.instagram.com/annahipszdesign/" target="_blank">
          <v-icon class="ma-1">
            mdi-instagram
          </v-icon>
        </a>
        <a href="https://www.linkedin.com/in/annahipsz/" target="_blank">
          <v-icon class="ma-1">
            mdi-linkedin
          </v-icon>
        </a>
        <a href="https://www.facebook.com/annahipsz" target="_blank">
          <v-icon class="ma-1">
            mdi-facebook
          </v-icon>
        </a>
        <v-spacer></v-spacer>
        ©
        <v-img :src="signature" max-height="50px" max-width="100px"> </v-img>
      </v-row>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    signature: require('@/assets/SignatureGrey.png')
  })
}
</script>

<style>

</style>
