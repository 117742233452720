<template>
  <v-container>
    <v-row id="start">
      <v-col cols=12>
        <v-row
          class="ml-10 mr-5 mb-10 elevation-0"
          v-for="collection in posters.posters"
          :key="collection.name"
          :id="collection.name"
          >
          <v-col cols=12>
          
          </v-col>
          <v-row>
            <div
              class="ml-0"
              v-for="poster in (collection.items)"
              :key="poster.id"
            > 
              {{ poster.title | capitalize }} 
              <v-card
                class="mb-10 mr-4 mt-4"
                max-width="340"
                min-width="300"
                height="500"
              >
              <v-img
                @click="open_file(poster)"
                :src="poster.file"
              ></v-img>

              <v-card-actions class="mt-5" v-if="poster.pdf_available">
                <v-btn
                  outlined
                  elevation="2"
                  class="red"
                  dark
                  small
                  :href="file_pdf(poster.pdf_available)"
                >
                  Download in PDF
                </v-btn>
              </v-card-actions>
              </v-card>
            </div>
          </v-row>
        </v-row>
      </v-col>
    <v-dialog
      v-model="dialog"
      max-width="700"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ opened_file.description }}
        </v-card-title>

        <v-card-text>
        </v-card-text>

        <v-img
          :src="opened_file.file"
          >

        </v-img>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import posters from '../assets/posters'

export default {
  components: {
  },
  data: () => ({
    posters: {},
    dialog: false,
    opened_file: {
      file: '',
      description: ''
    }
  }),
  mounted () {
    this.posters = posters
  },
  methods: {
    open_file (file) {
      this.dialog = true
      this.opened_file.file = file.file
      this.opened_file.description = file.description
    },
    download_pdf (file) {
      console.log(file.pdf_available)
    },
    file_pdf (file) {
      return ('/' + file)
    }
  },
  computed: {
    random_posters () {
      const shuffled = posters.posters.sort(() => 0.5 - Math.random())
      let selected = shuffled.slice(0, 4)
      return selected
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      var values = value.split('_')
      var final = []
      values.forEach(element => {
        element = element.toString()
        element = element.charAt(0).toUpperCase() + element.slice(1)
        final = final + ' ' + element
      })
      return final
    },
    ordered: function (items) {
      console.log(items)
      items.sort(function (a, b) {
        if (a.id < b.id) return -1
        if (a.id > b.id) return 1
        return 0
      })
      return items
    }
  }
}
</script>
