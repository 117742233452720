<template>
  <v-container>
    <v-row>
      <v-col cols=12>
        <v-row
          v-for="collection in random_patterns"
          v-bind:key="collection.name"
          class="ml-10 mb-5"
          >
            <v-card
              class="ma-2"
              max-width="300"
              min-width="300"
              height="300"
              v-for="pattern in collection.items"
              v-bind:key="pattern.id"
              @click="go_to_patterns"
            >
              <v-img
                :src="pattern.file"
              ></v-img>

            </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import patterns from '../../assets/patterns'

export default {
  components: {
  },
  data: () => ({
    patterns: {}
  }),
  created () {
    this.patterns = patterns
  },
  methods: {
    go_to_patterns () {
      this.$router.push({
        name: 'patterns'
      })
    }
  },
  computed: {
    random_patterns () {
      var patterns = []
      this.patterns.patterns.forEach(element => {
        let selected = element.items.slice(0, 3)
        patterns.push({ 'name': element.name, 'items': selected })
      })
      return patterns
    }
  }
}
</script>