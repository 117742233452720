<template>
  <v-navigation-drawer
    v-model="menu"
    app
    temporary
    @transitionend="toggleMenu"
  >
  <v-list dense>

    <v-list-item two-line class="mb-10">
      <v-card
        class="mt-auto"
        elevation="0"
      >
        <v-img
          max-height="100%"
          max-width="600px"
          src="@/assets/My photo - profile.png"
          @click="home()"
        >
        </v-img>

        <v-card-text class="pb-0 pl-5 display-1">
          Anna Hipsz
        </v-card-text>

        <v-card-text class="text-caption text-center">
          <div>
            <v-icon>
              mdi-map-marker
            </v-icon>
            Västerås, Sweden
          </div>

          <div>
            annahipsz.design@gmail.com
          </div>
        </v-card-text>

      </v-card>
    </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pl-16">
        <v-avatar>
          <img :src="require('@/assets/english.png')" alt="English" @click="set_lang('en')">
        </v-avatar>
        <v-avatar>
          <img :src="require('@/assets/polish.png')" alt="Polish" @click="set_lang('pl')">
        </v-avatar>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>

export default {
  data () {
    return {
      menu: false,
      links: [
        {
          to: '/',
          icon: 'mdi-view-dashboard',
          text: '$vuetify.menu.home'
        },
        {
          to: '/contact',
          icon: 'mdi-view-dashboard',
          text: '$vuetify.menu.contact'
        },
        {
          to: '/illustrations',
          icon: 'mdi-view-dashboard',
          text: '$vuetify.menu.illustrations'
        },
        {
          icon: 'mdi-folder',
          to: '/patterns',
          text: '$vuetify.menu.patterns',
          subLinks: [
            {
              text: '$vuetify.menu.all',
              to: '/patterns/#start',
              icon: 'mdi-view-list'
            },
            {
              text: '$vuetify.menu.pastel_syrup',
              to: '/patterns/#pastel_syrup',
              icon: 'mdi-view-list'
            },
            {
              text: '$vuetify.menu.japanese_garden',
              to: '/patterns/#japanese_garden',
              icon: 'mdi-plus'
            },
            {
              text: '$vuetify.menu.colour_block_abstract',
              to: '/patterns/#colour_block_abstract',
              icon: 'mdi-plus'
            }
          ]
        },
        {
          icon: 'mdi-application',
          text: '$vuetify.menu.posters',
          subLinks: [
            {
              text: '$vuetify.menu.all',
              to: '/posters/#start',
              icon: 'mdi-view-list'
            },
            {
              text: '$vuetify.menu.halloween',
              to: '/posters/#halloween',
              icon: 'mdi-view-list'
            },
            {
              text: '$vuetify.menu.contest',
              to: '/posters/#contest',
              icon: 'mdi-plus'
            },
            {
              text: '$vuetify.menu.polish_venus',
              to: '/posters/#polish_venus',
              icon: 'mdi-plus'
            }
          ]
        }
      ]
    }
  },
  props: {
    drawer: Boolean
  },
  mounted () {
    this.menu = this.drawer
  },
  watch: {
    drawer () {
      this.menu = this.drawer
    }
  },
  methods: {
    set_lang (lang) {
      this.$vuetify.lang.current = lang
    },
    toggleMenu () {
      if (this.drawer !== this.menu) {
        this.$emit('toggleMenu')
      }
    },
    home () {
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>

<style>

</style>
