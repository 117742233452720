<template>
  <v-container fluid>
    <v-row class="ma-2" v-if="tab == 0">
      <v-card
      class="mx-auto elevation-0"
      
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-h3">
              Anna Hipsz
            </div>
            <div class="text-h5 mb-4">
              Aspiring UX&UI Designer / Freelance Graphic Designer
            </div>
            <div class="text-subtitle-1 mb-n3">
              Welcome to my page!<br>
            </div>
          </v-list-item-content>

          <v-list-item-avatar
            tile
            size="200"
            color="grey"
            rounded
            v-if="!$vuetify.breakpoint.xs"
          >
            <v-img
              src="@/assets/My photo - profile.png"
              @click="home()"
            >
            </v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-card-actions class="ml-2">
          <v-btn
            color="gray"
            dark
            @click="readFile('/anna_hipsz_cv.pdf')"
          >
            Check my CV
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>

    <v-divider v-if="tab == 0"></v-divider>
    <v-row class="mt-2" justify="center">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <UxUi/>
        </v-tab-item>
        <v-tab-item>
          <Graphic/>
        </v-tab-item>
        <v-tab-item>
          <Patterns/>
        </v-tab-item>
        <v-tab-item>
          <About/>
        </v-tab-item>
      </v-tabs-items> 
    </v-row>

  </v-container>
</template>

<script>
import Patterns from './tabs/Patterns'
import Graphic from './tabs/Graphic'
import UxUi from './tabs/UxUi'
import About from './Contact'

export default {
  components: {
    Patterns,
    Graphic,
    UxUi,
    About
  },
  data: () => ({
    
    patterns: {},
  }),
  created () {

  },
  methods: {
    readFile(file) {
      window.open(file, '_blank') //to open in new tab
    }
  },
  computed: {
  },
  props: {
    tab: Number
  }
}
</script>
