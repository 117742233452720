export default {
  'uxui': [
    { 'name': 'websites',
      'items': [

      ]
    },
    { 'name': 'mobile_apps',
    'items': [
      {
        'id': '2',
        'file': require('../assets/uxui/guide_me/guide_me.json'),
        'description': 'Case: Mentoring App for Expats & Immigrants',
        'title': 'Guide Me App & Website',
        'thumbnail': require('../assets/uxui/guide_me_t.png'),
        'url' : 'https://www.figma.com/proto/4yjTyN56oSxBXhQLFfAofP/GUIDE-ME?node-id=127%3A471&scaling=scale-down&page-id=27%3A318&starting-point-node-id=127%3A471',
        'name': 'hip_cinema'
      },
      {
        'id': '1',
        'file': require('../assets/uxui/hip_cinema/hip_cinema.json'),
        'description': 'Case: Ticket Booking App',
        'title': 'Hip Cinema Website',
        'thumbnail': require('../assets/uxui/hip_cinema_t.png'),
        'url' : 'https://xd.adobe.com/view/24e71460-ead2-48d9-a0c7-439eeef69357-71b4/',
        'name': 'hip_cinema'
      },
      {
        'id': '0',
        'file': require('../assets/uxui/inter_arte/inter_arte.json'),
        'description': 'Case: Art Gallery Tour App',
        'title': 'Art History App',
        'thumbnail': require('../assets/uxui/interarte_t.png'),
        'url' : 'https://www.figma.com/proto/J5HMDKsZbDqLstRVIruz97/UX-GOOGLE---Inter-Arte-My-History-Art-App?node-id=334%3A2428&scaling=scale-down&page-id=268%3A1520&starting-point-node-id=334%3A2428'
      }
    ]
    },
    { 'name': 'watch_apps',
    'items': [
    ]
    }
  ]
}
