export default {
  follow_me: 'Obserwuj mnie',
  menu: {
    home: 'Strona Główna',
    all: 'Wszystkie',
    contact: 'O mnie',
    about: 'About',
    patterns: 'Wzory',
    graphic: 'Grafika',
    uxui: 'UX/UI',
    posters: 'Plakaty',
    illustrations: 'Ilustracje',
    water_colours: 'Akwarele',
    halloween: 'Halloween',
    contest: `Konkurs`,
    polish_venus: 'Polska Venus',
    pastel_syrup: 'Pastel Syrup',
    japanese_garden: 'Japanese Garden',
    colour_block_abstract: 'Colour Block Abstract'
  }
}
