<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="8">
        <span class="text-h3">
          Hello, &#128075;<br><br>
        </span>
        <p align="justify">
          <span class="blue--text"> I’m an aspiring UX&UI Designer</span> / freelance Graphic Designer, passionate about aesthetics, Information Architecture, clear communication. <br> <br>
          I have recently finished <span class="green--text"> Google UX Certificate at Coursera </span>and I am currently looking for a UX/UI job or internship. <br> <br>
          I have a master's in History of Art and gathered work experience in; Fashion Writing, Content Creation, Communication, Customer Service, freelance Graphic Design and Recruitment. 
          I am eager to leverage a diverse range of my skills in an innovative professional setting. <br><br>
          Me in a nutshell: highly adaptable, creative person with Marie Kondo’s need to organize & categorize things (or like Monica, from Friends &#128521;). <br><br>
          Thank you for your time to check out my page, <br><br>
          Anna<br><br><br>
          <span class="text-h6 blue--text">
            Let’s get in touch !<br><br>
          </span>
          

          annahipsz.design@gmail.com<br><br>
          My socials: 
          <a href="https://www.instagram.com/annahipszdesign/" target="_blank">
          <v-icon class="ma-1">
            mdi-instagram
          </v-icon>
          </a>
          <a href="https://www.linkedin.com/in/annahipsz/" target="_blank">
            <v-icon class="ma-1">
              mdi-linkedin
            </v-icon>
          </a>
          <br>
          <v-btn
            class="mt-7"
            color="gray"
            dark
            @click="readFile('/anna_hipsz_cv.pdf')"
          >
            Check my CV
          </v-btn>
        </p>
      </v-col>
      <v-col cols="4" v-if="!$vuetify.breakpoint.xs">
        <v-img
          class="elevation-3"
          max-height="100%"
          src="@/assets/My photo - full.jpg"
        >
        </v-img>
      </v-col>

    </v-row>
    <v-row v-if="$vuetify.breakpoint.xs">
      <v-col>
              <v-img
          class="elevation-3"
          max-height="100%"
          src="@/assets/My photo - full.jpg"
        >
        </v-img>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>

export default {
  components: {
  },
  methods: {
      readFile(file) {
        window.open(file, '_blank') //to open in new tab
      }
    },
}
</script>

<style>

</style>
