<template>
  <v-app>
    <Navigation v-on:toggleMenu="toggleMenu" v-bind:drawer="drawer"></Navigation>
    <Appbar v-on:toggleMenu="toggleMenu" v-on:selecttab="selecttab($event)" v-bind:drawer="drawer"></Appbar>
    <v-main>
      <router-view :tab="tab"/>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Navigation from '@/components/core/Navigation'
import Footer from '@/components/core/Footer'
import Appbar from '@/components/core/Appbar'

export default {
  name: 'App',
  components: {
    Navigation,
    Appbar,
    Footer,
  },
  data: () => ({
    drawer: false,
    tab: null
  }),
  methods: {
    toggleMenu () {
      this.drawer = !this.drawer
    },
    mounted () {
      this.$router.push({
        name: 'home'
      })
    },
    selecttab(event) {
      this.tab = event
      if (this.$route.name != 'home') {
        this.$router.push({
          name: 'home'
        })
      }
    }
  },
  mounted () {
    this.selecttab(3)
  }
}
</script>

<style>

</style>
