export default {
  'illustrations': [
    { 'name': 'The_Holiday Portrait',
      'items': [
        {
          'id': '0',
          'file': require('../assets/illustrations/The_Holiday.png'),
          'description': 'The Holiday - Cameron Diaz scene in the cottage house',
          'title': 'The_Holiday',
          'thumbnail': require('../assets/illustrations/The_Holiday.png')
        }
      ]
    },
    { 'name': 'Fran_Lebowitz Portrait',
      'items': [
        {
          'id': '0',
          'file': require('../assets/illustrations/Fran_Lebowitz.png'),
          'description': 'Fran Lebowitz - New York Writer',
          'title': 'Fran Lebowitz Portrait',
          'thumbnail': require('../assets/illustrations/Fran_Lebowitz.png')
        }
      ]
    }
  ]
}

