export default {
  'books': [
    { 'name': 'Famous and (un)forgotten',
      'items': [
        {
          'id': '0',
          'file': require('../assets/books/book1.png'),
          'description': 'Zlecenie: Okładka książki Tomasza Gawinskiego',
          'title': 'Znani i (nie)zapomniani',
          'thumbnail': require('../assets/books/book1_t.png')
        }
      ]
    },
    { 'name': 'Peach_Blonde Portrait',
    'items': [
      {
        'id': '1',
        'file': require('../assets/illustrations/Peach_Blonde_A4.png'),
        'description': 'Peach Blonde - Commisionned Portrait',
        'title': 'Peach Blonde Portrait',
        'thumbnail': require('../assets/illustrations/Peach_Blonde_A4.png')
      }
    ]
  },
  ]
}
