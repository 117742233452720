<template>
  <v-app-bar
   dense
   app
   color="blue-grey lighten-5"
   @click="force_tab"
   >
    <v-app-bar-nav-icon v-if="!drawer" @click="toggleMenu"></v-app-bar-nav-icon>
    <template v-slot:extension>
      <v-tabs  v-model="tab" right >
        <v-tab >
          {{ $vuetify.lang.t('$vuetify.menu.uxui') }}
        </v-tab>

        <v-tab>
          {{ $vuetify.lang.t('$vuetify.menu.graphic') }}
        </v-tab>

        <v-tab >
          {{ $vuetify.lang.t('$vuetify.menu.patterns') }}
        </v-tab>

        <v-tab>
          {{ $vuetify.lang.t('$vuetify.menu.contact') }}
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>

</template>

<script>
export default {
  components: {

  },
  data () {
    return {
      tab: null
    }
  },
  methods: {
    toggleMenu () {
      this.$emit('toggleMenu')
    },
    set_lang (lang) {
      this.$vuetify.lang.current = lang
    },
    force_tab () {
      this.$emit('selecttab', this.tab)
    }
  },
  props: {
    drawer: Boolean
  },
  watch: {
    tab () {
      this.$emit('selecttab', this.tab)
    }
  },
}
</script>

<style>

</style>
