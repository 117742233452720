export default {
  'posters': [
    { 'name': 'halloween',
      'items': [
        {
          'id': '0',
          'file': require('../assets/posters/DRACULAII.png'),
          'description': 'Poster Dracula',
          'title': 'Dracula',
          'thumbnail': require('../assets/posters/DRACULAII.png')
        }
      ]
    },
    { 'name': 'contest',
      'items': [
        {
          'id': '2',
          'file': require('../assets/posters/Whistleblower.png'),
          'description': 'Poster for Competition Entry. Whistleblower Issue. Linia Etyki',
          'title': 'Competition Entry. Whistleblower Issue. Linia Etyki',
          'thumbnail': require('../assets/posters/Whistleblower.png')
        }
      ]
    },
    { 'name': 'polish_venus',
      'items': [
        {
          'id': '3',
          'file': require('../assets/posters/PolishVenusThunder.jpg'),
          'description': 'Protest Poster: Polish Venus',
          'title': 'Protest Poster: Polish Venus',
          'thumbnail': require('../assets/posters/PolishVenusThunder.jpg'),
          'pdf_available': 'PolishVenusThunder.pdf'
        }
      ]
    }
  ]
}
