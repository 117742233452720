export default {
  'patterns': [
    { 'name': 'pastel_syrup',
      'items': [
        {
          'id': '0',
          'file': require('../assets/patterns/PS_30.png'),
          'description': 'Pastel Syrup 1',
          'title': 'Pastel Syrup 1',
          'thumbnail': require('../assets/patterns/PS_30.png')
        },
        {
          'id': '1',
          'file': require('../assets/patterns/PS_28.png'),
          'description': 'Pastel Syrup 2',
          'title': 'Pastel Syrup 2',
          'thumbnail': require('../assets/patterns/PS_28.png')
        },
        {
          'id': '2',
          'file': require('../assets/patterns/PS_20.png'),
          'description': 'Pastel Syrup 3',
          'title': 'Pastel Syrup 3',
          'thumbnail': require('../assets/patterns/PS_20.png')
        },
        {
          'id': '3',
          'file': require('../assets/patterns/PS_18.png'),
          'description': 'Pastel Syrup 4',
          'title': 'Pastel Syrup 4',
          'thumbnail': require('../assets/patterns/PS_18.png')
        },
        {
          'id': '4',
          'file': require('../assets/patterns/PS_34.png'),
          'description': 'Pastel Syrup 5',
          'title': 'Pastel Syrup 5',
          'thumbnail': require('../assets/patterns/PS_34.png')
        },
        {
          'id': '5',
          'file': require('../assets/patterns/PS_32.png'),
          'description': 'Pastel Syrup 6',
          'title': 'Pastel Syrup 6',
          'thumbnail': require('../assets/patterns/PS_32.png')
        }
      ]
    },
    { 'name': 'japanese_garden',
      'items': [
        {
          'id': '6',
          'file': require('../assets/patterns/JP_24.png'),
          'description': 'Japanese Garden 1',
          'title': 'Japanese Garden 1',
          'thumbnail': require('../assets/patterns/JP_24.png')
        },
        {
          'id': '7',
          'file': require('../assets/patterns/JP_16.png'),
          'description': 'Japanese Garden 2',
          'title': 'Japanese Garden 2',
          'thumbnail': require('../assets/patterns/JP_16.png')
        },
        {
          'id': '8',
          'file': require('../assets/patterns/JP_22.png'),
          'description': 'Japanese Garden 3',
          'title': 'Japanese Garden 3',
          'thumbnail': require('../assets/patterns/JP_22.png')
        },
        {
          'id': '9',
          'file': require('../assets/patterns/JP_26.png'),
          'description': 'Japanese Garden 4',
          'title': 'Japanese Garden 4',
          'thumbnail': require('../assets/patterns/JP_26.png')
        },
        {
          'id': '10',
          'file': require('../assets/patterns/JP_12.png'),
          'description': 'Japanese Garden 5',
          'title': 'Japanese Garden 5',
          'thumbnail': require('../assets/patterns/JP_12.png')
        },
        {
          'id': '11',
          'file': require('../assets/patterns/JP_14.png'),
          'description': 'Japanese Garden 6',
          'title': 'Japanese Garden 6',
          'thumbnail': require('../assets/patterns/JP_14.png')
        }
      ]
    },
    { 'name': 'colour_block_abstract',
      'items': [
        {
          'id': '12',
          'file': require('../assets/patterns/CBA_20.png'),
          'description': 'Color Block Abstract 1',
          'title': 'Color Block Abstract 1',
          'thumbnail': require('../assets/patterns/CBA_20.png')
        },
        {
          'id': '13',
          'file': require('../assets/patterns/CBA_13.png'),
          'description': 'Color Block Abstract 2',
          'title': 'Color Block Abstract 2',
          'thumbnail': require('../assets/patterns/CBA_13.png')
        },
        {
          'id': '14',
          'file': require('../assets/patterns/CBA_3.png'),
          'description': 'Color Block Abstract 3',
          'title': 'Color Block Abstract 3',
          'thumbnail': require('../assets/patterns/CBA_3.png')
        },
        {
          'id': '15',
          'file': require('../assets/patterns/CBA_5.png'),
          'description': 'Color Block Abstract 4',
          'title': 'Color Block Abstract 4',
          'thumbnail': require('../assets/patterns/CBA_5.png')
        },
        {
          'id': '16',
          'file': require('../assets/patterns/CBA_22.png'),
          'description': 'Color Block Abstract 5',
          'title': 'Color Block Abstract 5',
          'thumbnail': require('../assets/patterns/CBA_22.png')
        },
        {
          'id': '17',
          'file': require('../assets/patterns/CBA_10.png'),
          'description': 'Color Block Abstract 6',
          'title': 'Color Block Abstract 6',
          'thumbnail': require('../assets/patterns/CBA_10.png')
        },
        {
          'id': '18',
          'file': require('../assets/patterns/CBA_18.png'),
          'description': 'Color Block Abstract 7',
          'title': 'Color Block Abstract 7',
          'thumbnail': require('../assets/patterns/CBA_18.png')
        },
        {
          'id': '19',
          'file': require('../assets/patterns/CBA_7.png'),
          'description': 'Color Block Abstract 8',
          'title': 'Color Block Abstract 8',
          'thumbnail': require('../assets/patterns/CBA_7.png')
        },
        {
          'id': '20',
          'file': require('../assets/patterns/CBA_24.png'),
          'description': 'Color Block Abstract 9',
          'title': 'Color Block Abstract 9',
          'thumbnail': require('../assets/patterns/CBA_24.png')
        },
        {
          'id': '21',
          'file': require('../assets/patterns/CBA_23.png'),
          'description': 'Color Block Abstract 10',
          'title': 'Color Block Abstract 10',
          'thumbnail': require('../assets/patterns/CBA_23.png')
        }
      ]
    }
  ]
}
