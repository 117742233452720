import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Contact from './views/Contact.vue'
import Patterns from './views/Patterns.vue'
import Posters from './views/Posters.vue'
import UXProject from './views/UXProject.vue'
import Illustrations from './views/Illustrations.vue'
import NotFound from './components/core/NotFound.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/illustrations',
      name: 'illustrations',
      component: Illustrations
    },
    {
      path: '/patterns',
      name: 'patterns',
      component: Patterns
    },
    {
      path: '/posters',
      name: 'posters',
      component: Posters
    },
    {
      path: '/uxproject',
      name: 'uxproject',
      component: UXProject,
      props: true 
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    { path: '*', component: NotFound }
  ],
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})
