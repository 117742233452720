<template>
  <v-container>
    <v-row
      v-for="slide in project.file.slides"
      v-bind:key="slide.id"
    >
    <v-col>
      <v-card
        class="mb-5 mx-auto elevation-1"
        max-width="900"
      >
        <v-img
          class="white--text align-end"
          :src="slide.file" 
        >
    
        </v-img>

        <v-card-title
          v-if="show(slide.links)"
        >
        Links:
        </v-card-title>
          
        <v-card-actions>
          <v-row class="ml-2">
            <span
              v-for="link in slide.links"
              v-bind:key="link._id"
            >
              <v-btn
                  color="red"
                  class="mr-2 mb-2"
                  dark
                  @click="slide_url(link.link)"
                >
                {{link.text}}
              </v-btn>
            </span>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>

    </v-row>
  </v-container>
</template>

<script>

export default {
components: {
  },
  data: () => ({
    opened_file: {
    },
    defaultImage: require("@/assets/background.jpg")
  }),
  methods: {
    load_slides() {
      try {
        fetch(this.project.file)
          .then(response => {
            response.json()
            .then(
              (data) => {
                this.opened_file = data
            })
          })
      } catch (error) {
        console.error(error);
      }
    },
    slide_url (url) {
      window.open(url, '_blank') //to open in new tab
    },
    show (array) {
      if (array.length > 0)
        return true
      else
        return false
    }
  },
  props: {
    project: Object
  },
  mounted () {
    this.load_slides() 
  },
  computed: {

  }
}
</script>

<style>

</style>