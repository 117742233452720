<template>
  <v-container fluid>
    <v-row >
      <v-col cols=12>
        <v-row
          v-for="collection in random_uxui"
          v-bind:key="collection.name"
          class="ml-5 mb-5"
          >
            
            <v-card
              class="ma-2 elevation-0"
              max-width="500"
              min-width="300"
              v-for="uxui in collection.items"
              v-bind:key="uxui.id"
              
            >
            <v-hover v-slot:default="{ hover }">
              <v-img
                class="row-pointer elevation-3"
                @click="open(uxui)"
                :src="uxui.thumbnail"
              >
                <v-fade-transition>
                  <div v-if="hover" class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal display-3 white--text" style="height: 100%;"> 
                    Case Study 
                  </div>
                </v-fade-transition>
              </v-img>
            </v-hover>
              <v-card-actions>
                <v-list-item class="grow">
                  {{uxui.title}}
                  <v-row
                    justify="end"
                  >
                    <v-btn color="blue-grey lighten-5" small :href="uxui.url" target="_blank">
                      Link to Prototype
                    </v-btn>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-card>

        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import uxui from '../../assets/uxui'

export default {
  components: {
  },
  data: () => ({
    uxui: {},
    modal: false
  }),
  created () {
    this.uxui = uxui
  },
  methods: {
    open (e) {
      this.$router.push({
        name: 'uxproject',
        params: {
          project: e
        }
      })
      // console.log(e)
    },
    readFile(file) {
      window.open(file, '_blank') //to open in new tab
    }
  },
  computed: {
    random_uxui () {
      var _uxui = []
      this.uxui.uxui.forEach(element => {
        let selected = element.items.slice(0, 4)
        _uxui.push({ 'name': element.name, 'items': selected })
      })
      return _uxui
    }
  }
}
</script>

<style scoped>
.row-pointer {
  cursor: pointer;
}
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
}

</style>