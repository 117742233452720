<template>
  <v-container>
    <v-row id="start">
        <v-btn
          class="ml-3"
          color="red lighten-3"
          small
          fab
          dark
          top
          left
          @click="back()"
        >
        <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>

      <v-col cols=12>
        <v-row
          class="ml-10 mr-5 mb-10 elevation-0"
          v-for="collection in patterns.patterns"
          v-bind:key="collection.name"
          :id="collection.name"
          >
          <v-col cols=12>
            <div class="ml-n4"> {{ collection.name | capitalize }} </div>
          </v-col>
          <v-row>
            <v-card
              class="ma-2"
              max-width="300"
              min-width="300"
              height="300"
              v-for="pattern in collection.items"
              v-bind:key="pattern.id"
              @click="open_file(pattern)"
            >
              <v-img
                :src="pattern.file"
              ></v-img>

            </v-card>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="700"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ opened_file.description }}
        </v-card-title>

        <v-card-text>
        </v-card-text>

        <v-img
          :src="opened_file.file"
          >

        </v-img>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import patterns from '../assets/patterns'

export default {
  components: {
  },
  data: () => ({
    patterns: {},
    dialog: false,
    opened_file: {
      file: '',
      description: ''
    }
  }),
  mounted () {
    this.patterns = patterns
  },
  methods: {
    open_file (file) {
      this.dialog = true
      this.opened_file.file = file.file
      this.opened_file.description = file.description
    },
    back () {
      this.$router.push({
        name: 'home'
      })
    }
  },
  computed: {
    random_prints () {
      const shuffled = patterns.patterns.sort(() => 0.5 - Math.random())
      let selected = shuffled.slice(0, 4)
      return selected
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      var values = value.split('_')
      var final = []
      values.forEach(element => {
        element = element.toString()
        element = element.charAt(0).toUpperCase() + element.slice(1)
        final = final + ' ' + element
      })
      return final
    }
  }
}
</script>
